import React, {useEffect, useState} from "react";
import placeholderImage from "../images/cards/placeholder.png"
import TopPlayerField from "./fields/TopPlayerField";
import LeftPlayerField from "./fields/LeftPlayerField";
import RightPlayerField from "./fields/RightPlayerField";
import {CountdownCircleTimer} from 'react-countdown-circle-timer'
import hostImage from "../images/host.png";
import PlayerCalls from "./PlayerCalls";

function TimerComponent({ initialRemainingTime, timeCountdown }) {
    return (
        <CountdownCircleTimer
            initialRemainingTime={initialRemainingTime}
            size={70}
            isPlaying={true}
            duration={timeCountdown}
            colors={['#004777', '#F7B801', '#A30000', '#A30000']}
            colorsTime={[7, 5, 2, 0]}
        >
            {({ remainingTime }) => remainingTime}
        </CountdownCircleTimer>
    );
}

const Game = ({game, connection, turnLaundry, callDirtyLaundry, callWhiteLaundry, knock, callNoLaundry, check, fold, callMoveOnToNextSet, parsedTimerInfo}) => {
    const [selectedCard, setSelectedCard] = useState(null);
    const [timeCountdown, setTimeCountdown] = useState(12);
    const [remainingTimeCountdown, setRemainingTimeCountdown] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [key, setKey] = useState(0);

    useEffect(() => {
        if (parsedTimerInfo) {
            setIsPlaying(false);
            setRemainingTimeCountdown(parsedTimerInfo.Seconds);
            setKey(key + 1);
            setIsPlaying(true);
        }

        const handleVisibilityChange = () => {
            if (!document.hidden) {
                setIsPlaying(true);
            }
        };

        if (!document.hidden) {
            setIsPlaying(true);
        }

        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [parsedTimerInfo]);

    const handleCardClick = (suit, value) => {
        setSelectedCard({ suit, value });
    };

    const playCard = async (suit, value) => {
        await connection.invoke("PlayCard", {suit, value});
        setSelectedCard(null);
    };

    return <div className='w-full flex-1'>
        <div className="absolute left-0 right-0 mx-auto w-fit text-white">
            <div>{game.State}</div>
            <div><small>Strafpunten:</small> {game.PenaltyPoints}</div>
        </div>
        <div className="absolute bg-gray-800 text-white">
            {game.Players.map((player, index) => (
                <div key={index} className="px-4 md:py-2.5 gap-4 flex justify-between items-center">
                    {player.IsHost ?
                        <img src={hostImage} alt={"hostImage"} className="w-8 mr-0.5"/>
                        : <div className="w-14"></div>
                    }
                    <p className="text-left w-full">{player.Name}:</p>
                    <p>{player.PenaltyPoints}</p>
                </div>
            ))}
        </div>
        <div className="w-full flex justify-end">
            <TimerComponent initialRemainingTime={remainingTimeCountdown} timeCountdown={timeCountdown} key={key} />
        </div>
        <div className="grid grid-cols-3 gap-2 px-2 w-full">
            <div className="text-center w-full">
                <div className="w-full h-full"></div>
            </div>

            <div className="w-full flex justify-center">
                {game.Players.length === 2 ? (
                    <TopPlayerField user={game.Players[1]} turnLaundry={turnLaundry} game={game}/>
                ) : <div className={'h-[105px]'}></div>}

                {game.Players.length >= 4 ? (
                    <TopPlayerField user={game.Players[game.Players.length === 4 ? 2 : 3]} turnLaundry={turnLaundry} game={game}/>
                ) : <div className={'h-[105px]'}></div>}
            </div>

            <div className="text-center w-full">
                <div className="w-full h-full px-10"></div>
            </div>

            <div className="flex justify-end">
                {game.Players.length >= 5 ? (
                    <div className="h-[108px] w-full">
                        {(game.Players[2].CalledWhiteLaundry || game.Players[2].CalledDirtyLaundry) && game.State === "WaitingForLaundryTurnCalls" ?
                            <div className="absolute z-50 -rotate-90 md:ml-[190px] xl:ml-[210px] mt-[25px]">
                                <button className="p-2 m-1 bg-red-600 hover:bg-red-800 rounded-md text-white shadow-2xl border-2 border-black" onClick={() => turnLaundry(game.Players[2].Id)}>Omdraaien?</button>
                            </div>
                            : null
                        }
                        <LeftPlayerField user={game.Players[2]} game={game}/>
                    </div>
                ) : null}
            </div>

            <div className="text-center w-[50px]"></div>

            <div className="flex justify-start">
                {game.Players.length === 6 ? (
                    <div className="h-[108px] w-full">
                        {(game.Players[4].CalledWhiteLaundry || game.Players[4].CalledDirtyLaundry) && game.State === "WaitingForLaundryTurnCalls" ?
                            <div className="absolute z-50 rotate-90 xl:ml-[25px] mt-[25px]">
                                <button className="p-2 m-1 bg-red-600 hover:bg-red-800 rounded-md text-white shadow-2xl border-2 border-black" onClick={() => turnLaundry(game.Players[4].Id)}>Omdraaien?</button>
                            </div>
                            : null
                        }
                        <RightPlayerField user={game.Players[4]} game={game}/>
                    </div>
                ) : null}
            </div>

            <div className="flex justify-end mt-2">
                {game.Players.length >= 3 ? (
                    <div className="h-[108px] w-full">
                        {(game.Players[1].CalledWhiteLaundry || game.Players[1].CalledDirtyLaundry) && game.State === "WaitingForLaundryTurnCalls" ?
                            <div className="absolute z-50 -rotate-90 md:ml-[190px] xl:ml-[210px] mt-[25px]">
                                <button className="p-2 m-1 bg-red-600 hover:bg-red-800 rounded-md text-white shadow-2xl border-2 border-black" onClick={() => turnLaundry(game.Players[1].Id)}>Omdraaien?</button>
                            </div>
                            : null
                        }
                        <LeftPlayerField user={game.Players[1]} game={game}/>
                    </div>
                ) : null}
            </div>

            <div className="text-center w-[50px]"></div>

            <div className="flex justify-start mt-2">
                {game.Players.length >= 3 && game.Players.length < 6 ? (
                    <div className="h-[108px] w-full">
                        {(game.Players[game.Players.length - 1].CalledWhiteLaundry || game.Players[game.Players.length - 1].CalledDirtyLaundry) && game.State === "WaitingForLaundryTurnCalls" ?
                            <div className="absolute z-50 rotate-90 xl:ml-[25px] mt-[25px]">
                                <button className="p-2 m-1 bg-red-600 hover:bg-red-800 rounded-md text-white shadow-2xl border-2 border-black" onClick={() => turnLaundry(game.Players[game.Players.length - 1].Id)}>Omdraaien?</button>
                            </div>
                            : null
                        }
                        <RightPlayerField user={game.Players[game.Players.length - 1]} game={game}/>
                    </div>
                ) : null}

                {game.Players.length === 6 ? (
                    <div className="h-[108px] w-full">
                        {(game.Players[5].CalledWhiteLaundry || game.Players[5].CalledDirtyLaundry) && game.State === "WaitingForLaundryTurnCalls" ?
                            <div className="absolute z-50 rotate-90 xl:ml-[25px] mt-[25px]">
                                <button className="p-2 m-1 bg-red-600 hover:bg-red-800 rounded-md text-white shadow-2xl border-2 border-black" onClick={() => turnLaundry(game.Players[5].Id)}>Omdraaien?</button>
                            </div>
                            : null
                        }
                        <RightPlayerField user={game.Players[5]} game={game}/>
                    </div>
                ) : null}
            </div>

            <div className="text-center w-full">
                <div className="w-full h-full"></div>
            </div>

            <div className="w-full flex justify-center">
                <div id={`player-${game.Players[0].Id}`} className="rotate-180 flex flex-col gap-2">
                    <div className={'rotate-180 text-center'}>
                        {game.Players.length >= 1 ? (
                            <div className="flex gap-2 justify-center">
                                <p className={'text-xl text-white p-0 m-0'}>{game.Players[0].Name}</p>

                                <PlayerCalls player={game.Players[0]} />
                            </div>
                        ) : null}
                    </div>
                    <div className={`w-full flex justify-center ml-10 gap-2 md:gap-0 ${game.Players[0].HasFolded ? 'opacity-20' : ''}`}>
                        {
                            game.Players[0].Hand.map((card, index) => (
                                <img
                                    key={index}
                                    src={require(`../images/cards/${card.Value}${card.Suit}.svg`)}
                                    className={`w-[60px] md:w-[75px] relative transition-transform transform translate-y-0 hover:translate-y-[10px] ml-0 md:-ml-[40px] duration-75 ease-in-out -left-[40px] md:-left-[25px] rotate-180`}
                                    alt="cards-to-select"
                                    style={{zIndex: 40 - index}}
                                    onClick={() => handleCardClick(card.Suit, card.Value)}
                                />
                            ))
                        }
                        {
                            (game.Players[0].Hand.length <= 0) &&
                            <img
                                src={placeholderImage}
                                className={"w-[60px] md:w-[75px] relative ml-0 md:-ml-[40px] -left-[40px] md:-left-[25px] rotate-180"}
                                alt={"placeholderImage"}
                            />
                        }
                    </div>
                    <div className="w-full flex justify-center top-20">
                        {game.Players[0].LastPlayedCard ? (
                            <img
                                src={require(`../images/cards/${game.Players[0].LastPlayedCard.Value}${game.Players[0].LastPlayedCard.Suit}.svg`)}
                                className={`w-[40px] md:w-[75px] rounded-md relative z-20 rotate-180 
                                    ${game.Players[0].HasFolded ? 'opacity-20' : ''} ${
                                    game.Players[0].IsActive ? 'border-4 border-red-700' : ''
                                }`}
                                alt="last-played-card"
                                style={{
                                    top: '10px',
                                    boxShadow: game.StartedCard && game.StartedCard.Value === game.Players[0].LastPlayedCard.Value && game.StartedCard.Suit === game.Players[0].LastPlayedCard.Suit
                                        ? '0px 0px 10px 4px orange'
                                        : 'none'
                                }}
                            />
                        ) : (
                            <img
                                src={placeholderImage}
                                className={`w-[40px] md:w-[75px] relative z-20 ${
                                    game.Players[0].IsActive ? 'border-4 border-red-700' : ''
                                }`}
                                alt={placeholderImage}
                                style={{ top: '10px' }}
                            />
                        )}

                        {selectedCard ? (
                            <img
                                src={require(`../images/cards/${selectedCard.value}${selectedCard.suit}.svg`)}
                                className="w-[50px] md:w-[75px] relative z-50 rotate-180"
                                alt={(selectedCard.Value + selectedCard.Suit).toString()}
                                style={{
                                    marginLeft: '-60px',
                                    zIndex: 50,
                                    position: 'relative',
                                    left: '20px',
                                    top: '10px'
                                }}
                            />
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
        <div className="text-center w-full">
            <div className="w-full h-full flex justify-center pt-4 gap-4">
                {game.State !== "SetIsWonAndOver" ?
                    <div className="w-full h-full flex justify-center pt-4 gap-4">
                        {game.Players[0].CalledDirtyLaundry || game.Players[0].CalledWhiteLaundry ?
                            null
                            : <div className="flex gap-4">
                                {game.State === "WaitingForLaundryCalls" ?
                                    <button
                                        className={`bg-white border border-red-700 text-red-700 rounded-md p-4`}
                                        onClick={() => callDirtyLaundry()}>
                                        Vuile was!
                                    </button>
                                    : null
                                }

                                {game.State === "WaitingForLaundryCalls" ?
                                    <button
                                        className={`bg-white border border-red-700 text-red-700 rounded-md p-4`}
                                        onClick={() => callWhiteLaundry()}>
                                        Witte was!
                                    </button>
                                    : null
                                }

                                {game.State === "WaitingForLaundryCalls" ?
                                    <button
                                        className={`bg-white border border-red-700 text-red-700 rounded-md p-4`}
                                        onClick={() => callNoLaundry()}>
                                        No laundry!
                                    </button>
                                    : null
                                }
                            </div>
                        }

                        {game.Players[0].IsActive && game.Players[0].HasKnocked === false && game.State !== "PlayerKnocked" ?
                            <div className="flex gap-4">
                                <button
                                    className={`bg-white border border-red-700 text-red-700 rounded-md p-4`}
                                    onClick={() => knock()}>
                                    Knock
                                </button>
                            </div>
                            :null
                        }

                        {(game.State === "PlayerKnocked" && game.Players[0].HasKnocked === false) || (game.State === "Poverty" && !game.Players[0].HasPoverty) ?
                            <div className="flex gap-4">
                                <button
                                    className={`bg-white border border-red-700 text-red-700 rounded-md p-4`}
                                    onClick={() => fold()}>
                                    Fold
                                </button>
                                <button
                                    className={`bg-white border border-red-700 text-red-700 rounded-md p-4`}
                                    onClick={() => check()}>
                                    Check
                                </button>
                            </div>
                            : null
                        }

                        <button className={`bg-blue-500 hover:bg-blue-700 text-white rounded-md p-4 ${!selectedCard ? 'hidden' : ''}`} onClick={() => playCard(selectedCard.suit, selectedCard.value)} disabled={!selectedCard}>PlayCard</button>
                    </div>
                : <button
                        className={`bg-white border border-red-700 text-red-700 rounded-md p-4`}
                        onClick={() => callMoveOnToNextSet()}>
                        Move to next set
                    </button>
                }
            </div>
        </div>
    </div>
}

export default Game;