import hostImage from "../images/host.png";

const WaitingRoom = ({roomCode, users, startGame, connectedUser, removePlayer}) => {
    return <div className="w-full h-screen flex flex-col justify-between items-center pt-10">
        <div className="w-full">
            <div className="w-full flex justify-center px-2 sm:px-0">
                <div className="sm:w-[450px] p-4">
                    <div className="bg-white p-4 text-center">
                        <h1 className="text-3xl font-medium">RoomCode:</h1>
                        <h2 className="text-5xl font-bold">{roomCode}</h2>
                    </div>
                </div>
            </div>

            <div
                className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 text-center gap-10 px-4 xl:px-20 sm:pt-20 h-[400px] sm:h-full overflow-auto">
                {users.map((u, index) => (
                    <div key={index} className="w-full text-3xl bg-white rounded-lg flex flex-col justify-center">
                        <div className="flex w-full justify-center p-6 gap-2">
                            {u.IsHost ?
                                <img src={hostImage} className="w-10"/>
                                : null
                            }
                            <p>{u.Name}</p>
                            {!u.IsHost && connectedUser.IsHost ?
                                <button className="pl-2 bg-white text-red-700" onClick={() => removePlayer(u.Id)}>X</button>
                                : null
                            }
                        </div>
                    </div>
                ))}
            </div>
        </div>

        <div className="pb-4">
            {connectedUser.IsHost ?
                <form onSubmit={e => {
                    e.preventDefault();
                    startGame();
                }}>
                    <button
                        type="submit"
                        className={`px-6 sm:px-20 w-full bg-blue-500 hover:bg-blue-700 sm:text-4xl text-white rounded-md py-2.5 sm:py-4 mt-4`}
                    >
                        Start Game
                    </button>
                </form>
                :
                <div className="text-white">Waiting for host...</div>
            }
        </div>
    </div>
}

export default WaitingRoom;