import React, { useEffect } from 'react';

const AudioPlayer = ({ audioFile, audioRef }) => {
    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.src = audioFile;
        }
    }, [audioFile, audioRef]);

    return (
        <audio ref={audioRef}></audio>
    );
};

export default AudioPlayer;