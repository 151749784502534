import MessageContainer from "./MessageContainer";
import SendMessageForm from "./SendMessageForm";

const Chat = ( {messages, sendMessage, closeConnection, users, rooms, connectedUser}) => <div>
    <div className='rounded-lg pr-1'>
        <div className='grid'>
            <MessageContainer messages={messages} users={users} connectedUser={connectedUser}/>
            <SendMessageForm sendmessage={sendMessage} />
        </div>
    </div>
</div>

export default Chat;