import {useState} from "react";
import logo from "../images/toep-logo.png";

const Lobby = ({hostRoom}) => {
    const [user, setUser] = useState();
    const [room, setRoom] = useState();

    return <form className="w-full h-screen flex justify-center items-center" onSubmit={e => {
        e.preventDefault();
        hostRoom(user, room);
    }}>
        <div className="px-2 sm:px-0 w-full sm:w-96">
            <div className="space-y-4">
                <div className="relative w-full flex justify-center">
                    <img src={logo} className="h-60"/>
                </div>
                <h1 className={"text-center text-5xl font-bold"}>ToepMastor</h1>
                <div className="relative">
                    <input
                        type="text"
                        className="w-full border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:ring focus:border-blue-300"
                        placeholder="Name"
                        onChange={e => setUser(e.target.value)}
                    />
                </div>
                <div className="relative">
                    <label className={'text-white'}>Optional*</label>
                    <input
                        type="text"
                        className="w-full mt-1 border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:ring focus:border-blue-300"
                        placeholder="Room"
                        onChange={e => setRoom(e.target.value)}
                    />
                </div>
            </div>
            <button
                type="submit"
                className={`w-full bg-green-600 hover:bg-green-700 text-white rounded-md py-2 mt-4`}
            >
                Host
            </button>
        </div>
    </form>
}

export default Lobby;