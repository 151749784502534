import './App.css';
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import audioFile from './music/piranha.mp3';
import background from "./images/background-toepen.png";
import LobbyJoin from "./components/LobbyJoin";
import {useEffect, useRef, useState} from "react";
import {HubConnectionBuilder, LogLevel} from "@microsoft/signalr";
import WaitingRoom from "./components/WaitingRoom";
import Game from "./components/Game";
import AudioPlayer from "./components/AudioPlayer";
import Chat from "./components/chat/Chat";
import GameLog from "./components/GameLog";
import logo from "./images/toep-logo.png";
import LobbyHost from "./components/LobbyHost";

const Toep = () => {
    const [connection, setConnection] = useState();
    const [messages, setMessages] = useState([]);
    const [roomCode, setRoomCode] = useState("");
    const [users, setUsers] = useState([]);
    const [game, setGame] = useState(null);
    const audioRef = useRef(null);
    const [connectedUser, setConnectedUser] = useState(null);
    const [parsedTimerInfo, setParsedTimerInfo] = useState(null);
    const [hasJoinedRoom, setHasJoinedRoom] = useState(false);
    const [gameLog, setGameLog] = useState([]);
    const [showLobbyHost, setShowLobbyHost] = useState(false);
    const [showLobbyJoin, setShowLobbyJoin] = useState(false);

    let blinkTimers = [];
    let blinkingElement = null;

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            const message = 'Are you sure you want to leave?';
            event.returnValue = message;
            return message;
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    const joinRoom = async (userName, roomCode) => {
        try {
            const connection = new HubConnectionBuilder()
                .withUrl(process.env.REACT_APP_HUB_URL)
                .configureLogging(LogLevel.Information)
                .build();

            setConnections(connection);

            await connection.start().then(() => {
                console.log("Connected to hub");
            }).catch((error) => {
                console.log("Connection hub Error: " + error);
            });

            await connection.invoke("JoinRoom", {userName, roomCode});
            setConnection(connection);

            setRoomCode(roomCode);
            startAudio();
        } catch (e) {
            console.log(e)
        }
    }

    const hostRoom = async (userName, roomCode) => {
        try {
            const connection = new HubConnectionBuilder()
                .withUrl(process.env.REACT_APP_HUB_URL)
                .configureLogging(LogLevel.Information)
                .build();

            setConnections(connection);

            await connection.start().then(() => {
                console.log("Connected to hub");
            }).catch((error) => {
                console.log("Connection hub Error: " + error);
            });

            await connection.invoke("HostRoom", {userName, roomCode});
            setConnection(connection);

            startAudio();
        } catch (e) {
            setConnection(null);
            console.log(e);
        }
    }

    function setConnections(connection) {
        connection.on("ReceiveMessage", (sender, message) => {
            setMessages(messages => [...messages, {sender, message}]);
        });

        connection.on("ReceiveHasJoinedRoom", (hasJoinedRoom) => {
            setHasJoinedRoom(hasJoinedRoom);
        });

        connection.on("ReceiveFlashMessage", (type, message) => {
            console.log(type, message)
            switch (type) {
                case 'Info':
                    NotificationManager.info(message, 'Info', 5000);
                    break;
                case 'Success':
                    NotificationManager.success(message, 'Success', 5000);
                    break;
                case 'Warning':
                    NotificationManager.warning(message, 'Warning', 5000);
                    break;
                case 'Error':
                    console.error(message)
                    NotificationManager.error(message, 'Error', 5000);
                    break;
            }
        });

        connection.on("ReceiveTurnedCards", (victimCards) => {
            // console.log(JSON.stringify(JSON.parse(victimCards), undefined, 4));
            const victimCardsParsed = JSON.parse(victimCards);

            const notification = createNotification(victimCardsParsed);
            notificationContainer.appendChild(notification);
        });

        let hasSetIsWonAndOverExecuted = false;

        connection.on("ReceiveGame", (game) => {
            // console.log(JSON.stringify(JSON.parse(game), undefined, 4));

            const parsedGame = JSON.parse(game);

            if (parsedGame.State === "GameIsWonAndOver") {
                alert(`Game is over! Winner is ${parsedGame.Players.find(p => p.Id === parsedGame.WinnerIdOfGame).Name} \n refresh to play again`)
            }
            if (parsedGame.State === "Poverty") {
                parsedGame.Players.forEach(player => {
                    if (player.HasPoverty) {
                        alert(`Poverty! ${player.Name}`);
                    }
                });
            }

            if (parsedGame.State === "SetIsWonAndOver" && !hasSetIsWonAndOverExecuted) {
                hasSetIsWonAndOverExecuted = true;
                blinkingElement = document.getElementById(`player-${parsedGame.WinnerIdOfSet}`);
                let opacity = 0;

                for (let i = 0; i < 14; i++) {
                    const blinkTimeOut = setTimeout( function (){
                        blinkingElement.style.opacity = opacity;
                        opacity = opacity === 0 ? 1 : 0;
                    }, i * 350);
                    blinkTimers.push(blinkTimeOut);
                }
            } else if (parsedGame.State === "WaitingForLaundryCalls" && parsedGame.RoundNumber === 0) {
                hasSetIsWonAndOverExecuted = false;
                blinkTimers.forEach(timer => clearTimeout(timer));
                blinkTimers = [];
                if (blinkingElement) {
                    blinkingElement.style.opacity = 1;
                }
            }

            endAudio();

            setGame(parsedGame);
        });

        connection.on("ReceiveCountdown", (timerInfo) => {
            // console.log(JSON.stringify(JSON.parse(timerInfo), undefined, 4));
            const parsedInfo = JSON.parse(timerInfo);
            setParsedTimerInfo(parsedInfo);
        });

        const notificationContainer = document.getElementById("notification-container");

        connection.on("ReceiveUsersInRoom", (users, roomCode) => {
            const parsedUsers = JSON.parse(users);
            setUsers(parsedUsers);
            setRoomCode(roomCode);
        });

        connection.on("ReceiveConnectedUser", (user) => {
            if (user != null){
                // console.log(JSON.stringify(JSON.parse(user), undefined, 4));
                const parsedUser = JSON.parse(user);
                setConnectedUser(parsedUser);
            }
            else{
                connection.stop();
                setConnection(null);
            }
        });

        connection.on("ReceiveGameLog", (incomingGameLog) => {
            const parsedGameLog = JSON.parse(incomingGameLog);
            setGameLog(gameLog => [...gameLog, ...parsedGameLog]);
        });
    }

    function createNotification(victimCards) {
        const notification = document.createElement("div");
        notification.classList.add("bg-white", "rounded-lg", "shadow-xl", "w-full", "max-w-md", "mx-auto");

        notification.innerHTML = `
        <div class="fixed inset-0 flex items-center justify-center px-4 py-6 sm:p-20 w-full z-50">
            <div class="w-[500px]">
                <div class="bg-white rounded-t-md p-4 text-lg">
                    <h1>Player who turned: ${victimCards.PlayerName}</h1>
                    <h2>Victim: ${victimCards.VictimName}</h2>
                </div>
                <div class="grid grid-cols-4 p-4 bg-white">
                    ${victimCards.Hand.map((card, index) => `
                        <div class="px-2">
                            <img src="${require(`./images/cards/${card.Value}${card.Suit}.svg`)}" alt="card from victim">
                        </div>
                    `).join('')}
                </div>
                <div class="bg-white p-4 text-lg">
                    ${victimCards.victimBluffed ?
            `<h2>${String(victimCards.VictimName)} has to play with open cards!</h2>`
            : `<h2>${String(victimCards.PlayerName)} gets a penalty point!</h2>`
        }
                </div>
                <div class="p-4 bg-gray-100 border-t border-gray-200 rounded-b-md">
                  <button class="cursor-pointer w-full px-4 py-2 text-white bg-blue-500 hover:bg-blue-700 rounded-lg focus:outline-none accept-button">Okay</button>
                </div>
            </div>
        </div>
      `;

        const acceptButton = notification.querySelector(".accept-button");

        acceptButton.addEventListener("click", () => {
            notification.remove();
        });

        return notification;
    }

    const sendMessage = async (message) => {
        try {
            await connection.invoke("SendMessage", null, message);
        } catch (e) {
            console.log(e);
        }
    }

    const closeConnection = () => {
        try {
            connection.stop();
            setConnection(null);
        } catch (e) {
            console.log(e)
        }
    }

    const startAudio = () => {
        audioRef.current.play();
    };

    const endAudio = () => {
        audioRef.current.pause();
    };

    const startGame = async () => {
        try {
            await connection.invoke("StartGame");
        } catch (e) {
            console.log(e);
        }
    }

    const callMoveOnToNextSet = async () => {
        await connection.invoke("CallMoveOnToNextSet");
    }

    const callDirtyLaundry = async () => {
        await connection.invoke("CallDirtyLaundry");
    }

    const callWhiteLaundry = async () => {
        await connection.invoke("CallWhiteLaundry");
    }

    const callNoLaundry = async () => {
        await connection.invoke("CallNoLaundry");
    }

    const turnLaundry = async (victimId) => {
        await connection.invoke("TurnLaundry", victimId);
    }

    const removePlayer = async (victimId) => {
        await connection.invoke("KickPlayerInRoom", victimId);
    }

    const knock = async () => {
        await connection.invoke("Knock");
    }

    const check = async () => {
        await connection.invoke("Check");
    }

    const fold = async () => {
        await connection.invoke("Fold");
    }

    const skipLaundry = async () => {
        await connection.invoke("SkipLaundry");
    }

    const skipLaundryCalls = async () => {
        await connection.invoke("SkipLaundryCalls");
    }

    return <div style={{backgroundImage: `url(${background})`, width: "100vw", height: "100vh"}}>
        <div>
            <div id="notification-container"></div>
            {!connection || !hasJoinedRoom
                ?
                    <>
                        {
                            !showLobbyHost && !showLobbyJoin ? (
                                    <div className="w-full h-screen flex justify-center items-center">
                                        <div className="px-2 sm:px-0 w-full sm:w-96">
                                            <div className="space-y-4">
                                                <div className="relative w-full flex justify-center">
                                                    <img src={logo} className="h-60"/>
                                                </div>
                                                <h1 className={"text-center text-5xl font-bold"}>ToepMastor</h1>
                                            </div>
                                            <button
                                                type="submit"
                                                className={`w-full bg-blue-500 hover:bg-blue-700 text-white rounded-md py-2 mt-4`}
                                                onClick={() => setShowLobbyJoin(!showLobbyJoin)}
                                            >
                                                Join
                                            </button>
                                            <div className={'text-center mt-2 font-bold'}>------------- OR -------------</div>
                                            <button
                                                type="submit"
                                                className={`w-full bg-green-600 hover:bg-green-700 text-white rounded-md py-2 mt-2`}
                                                onClick={() => setShowLobbyHost(!showLobbyHost)}
                                            >
                                                Host
                                            </button>
                                        </div>
                                    </div>
                                )
                                :
                                <>
                                    {showLobbyHost && <LobbyHost hostRoom={hostRoom}/>}
                                    {showLobbyJoin && <LobbyJoin joinRoom={joinRoom}/>}
                                </>
                        }
                    </>
                :
                <div>
                    {!game
                        ? <WaitingRoom roomCode={roomCode} users={users} startGame={startGame}
                                       connectedUser={connectedUser} removePlayer={removePlayer}/>
                        : <div className="flex">
                            <div className="bg-black bg-opacity-60 h-[300px] w-[400px] hidden 2xl:block">
                                <GameLog gameLog={gameLog}/>
                            </div>
                            <Game game={game} connection={connection} turnLaundry={turnLaundry}
                                  callDirtyLaundry={callDirtyLaundry} callWhiteLaundry={callWhiteLaundry} knock={knock}
                                  callNoLaundry={callNoLaundry} check={check} fold={fold}
                                  callMoveOnToNextSet={callMoveOnToNextSet} parsedTimerInfo={parsedTimerInfo}/>
                            <div className="hidden lg:block">
                                <Chat messages={messages} sendMessage={sendMessage}
                                      closeConnection={closeConnection} users={users} connectedUser={connectedUser}/>
                            </div>
                        </div>
                    }
                </div>
            }
        </div>

        {/*        <button className="p-2 m-1 bg-white" onClick={() => callMoveOnToNextSet()}>Move to next set</button>*/}
        {/*        <button className="p-2 m-1 bg-white" onClick={() => check()}>Check</button>
        <button className="p-2 m-1 bg-white" onClick={() => fold()}>Fold</button>*/}

        <AudioPlayer audioFile={audioFile} audioRef={audioRef}/>
        <NotificationContainer/>
    </div>
}

export default Toep;