import React from 'react';
import blackShirtImage from '../images/black-shirt.svg';
import whiteShirtImage from '../images/white-shirt.svg';
import knockedImage from '../images/knocked.svg';

function PlayerImages({ player }) {
    return (
        <div>
            {player.CalledDirtyLaundry && (
                <img
                    src={blackShirtImage}
                    className="w-10 h-10"
                    alt="dirty laundry"
                />
            )}

            {player.CalledWhiteLaundry && (
                <img
                    src={whiteShirtImage}
                    className="w-10 h-10"
                    alt="white laundry"
                />
            )}

            {player.HasKnocked && (
                <img
                    src={knockedImage}
                    className="w-10 h-12"
                    alt="knocked"
                />
            )}
        </div>
    );
}

export default PlayerImages;