import cardBack from "../../images/cards/Back.png";
import placeholderImage from "../../images/cards/placeholder.png";
import React from "react";
import GameViewModel from "../../viewModels/GameViewModel";
import PlayerCalls from "../PlayerCalls";

const TopPlayerField = ({user, turnLaundry, game}) => {
    const gameViewModel = new GameViewModel(game);

    return <div className={'w-full flex justify-center'}>
        <div id={`player-${user.Id}`} className="w-full h-full flex-col">
            <div className="flex flex-col gap-1">
                <div>
                    <div className="flex gap-2 justify-center">
                        <p className="text-xl text-white text-center p-0 m-0">{user.Name}</p>
                        <PlayerCalls player={user} />
                    </div>


{/*                    {users.length === 2 ? (
                        <div className="flex gap-2 justify-center">
                            <p className={'text-xl text-white text-center p-0 m-0'}>{user.Name}</p>
                            <PlayerCalls player={user} />
                        </div>
                    ) : null}*/}
                </div>
                <div className="w-full flex justify-center relative">
                    <div className="w-full flex justify-center">
                        {(user.CalledWhiteLaundry || user.CalledDirtyLaundry) && gameViewModel.state === "WaitingForLaundryTurnCalls" ?
                            <div className="absolute z-50 md:mt-[55px]">
                                <button className="p-2 m-1 bg-red-600 hover:bg-red-800 rounded-md text-white shadow-2xl border-2 border-black" onClick={() => turnLaundry(user.Id)}>Omdraaien?</button>
                            </div>
                            : null
                        }

                        <div className={`w-full flex justify-center ml-[100px] ${user.HasFolded ? 'opacity-20' : ''}`}>
                            {
                                user.Hand.map((card, index) => (
                                    <img
                                        key={index}
                                        src={cardBack}
                                        className="w-[40px] md:w-[75px] relative z-20 -ml-[60px] -left-[25px]"
                                        alt={cardBack}
                                    />
                                ))
                            }
                            {
                                (user.Hand.length <= 0) &&
                                <img
                                    src={placeholderImage}
                                    className={"w-[40px] md:w-[75px] relative z-20 -ml-[60px] -left-[25px]"}
                                    alt={"placeholderImage"}
                                />
                            }
                        </div>
                    </div>
                </div>
                <div className="w-full flex justify-center">
                    <div>
                        {user.LastPlayedCard ? (
                            <img
                                src={require(`../../images/cards/${user.LastPlayedCard.Value}${user.LastPlayedCard.Suit}.svg`)}
                                className={`w-[40px] md:w-[75px] rounded-md relative z-20 ${user.HasFolded ? 'opacity-20' : ''} ${user.IsActive ? 'border-4 border-red-700' : ''}`}
                                alt="last-played-card"
                                style={{
                                    top: '10px',
                                    boxShadow: game.StartedCard && game.StartedCard.Value === user.LastPlayedCard.Value && game.StartedCard.Suit === user.LastPlayedCard.Suit
                                        ? '0px 0px 10px 4px orange'
                                        : 'none'
                                }}
                            />
                        ) : (
                            <img
                                src={placeholderImage}
                                className={`w-[40px] md:w-[75px] relative z-20 ${user.IsActive ? 'border-4 border-red-700' : ''}`}
                                alt={placeholderImage}
                                style={{ top: '10px' }}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default TopPlayerField;