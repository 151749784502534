import { useEffect, useRef} from "react";

const MessageContainer = ({ messages, users, connectedUser }) => {
    const messageRef = useRef();
    const standardColors = [
        '#FF411C',
        '#FFA500',
        '#FFFF00',
        '#33FF57',
        '#33A8FF',
        '#EF35AA',
        '#CE96FF',
    ];

    const userColors = {};

    for (let i = 0; i < users.length; i++) {
        userColors[users[i]] = standardColors[i];
    }

    const generateUserColor = (username) => {
        const index = users.indexOf(username);
        return index !== -1 ? standardColors[index] : null;
    }

    useEffect(() => {
        if (messageRef && messageRef.current){
            const {scrollHeight, clientHeight} = messageRef.current;
            messageRef.current.scrollTo({
               left: 0, top: scrollHeight - clientHeight,
               behavior: 'smooth',
            });
        }
    }, [messages]);

    return (
        <div ref={messageRef} className='h-[300px] w-[400px] bg-transparent overflow-auto rounded-sm mb-2 small-scrollbar'>
            {messages.map((m, index) => (
                <div key={index} className={`text-lg pt-2 pr-5 ${m.sender === connectedUser.Name ? 'flex justify-end' : ''}`}>
                    <div className={`bg-[#202C33] text-white p-2 max-w-full overflow-auto no-scrollbar inline-flex rounded-md ${m.sender === connectedUser.Name ? 'bg-blue-500' : ''}`}>
                        <div>
                            <div className={`flex justify-start text-sm ${m.sender === connectedUser.Name ? 'hidden' : ''}`} style={{ color: generateUserColor(m.sender) }}>
                                {m.sender}
                            </div>
                            <div className='text-md rounded-lg'>
                                {m.message}
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default  MessageContainer;