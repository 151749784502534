import React from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Privacy from "./Pages/Privacy";
import Toep from "./Toep";

function App() {
    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Toep/>}/>
                    <Route path="/privacy" element={<Privacy/>}/>
                </Routes>
            </BrowserRouter>
        </>
    );
}

export default App;