import placeholderImage from "../../images/cards/placeholder.png";
import cardBack from "../../images/cards/Back.png";
import React from "react";
import PlayerCalls from "../PlayerCalls";

const LeftPlayerField = ({user, game}) => {
    return <div id={`player-${user.Id}`} className="h-[108px] rotate-90 flex flex-col justify-end w-full gap-1">
        <div className="w-full flex justify-center">
            {user.LastPlayedCard ? (
                <img
                    src={require(`../../images/cards/${user.LastPlayedCard.Value}${user.LastPlayedCard.Suit}.svg`)}
                    className={`w-[40px] md:w-[75px] rounded-md relative z-20 ${user.HasFolded ? 'opacity-20' : ''} ${user.IsActive ? 'border-4 border-red-700' : ''}`}
                    alt="last-played-card"
                    style={{
                        top: '-10px',
                        boxShadow: game.StartedCard && game.StartedCard.Value === user.LastPlayedCard.Value && game.StartedCard.Suit === user.LastPlayedCard.Suit
                            ? '0px 0px 10px 4px orange'
                            : 'none'
                    }}
                />
            ) : (
                <img
                    src={placeholderImage}
                    className={`w-[40px] md:w-[75px] relative z-20 ${user.IsActive ? 'border-4 border-red-700' : ''}`}
                    alt={placeholderImage}
                    style={{ top: '-10px' }}
                />
            )}
        </div>
        <div className={`w-full flex justify-center ml-[50px] ${user.HasFolded ? 'opacity-20' : ''}`}>
            {
                user.Hand.map((card, index) => (
                    <img
                        key={index}
                        src={cardBack}
                        className="w-[40px] md:w-[75px] relative z-20 -ml-[60px] -left-[25px]"
                        alt={cardBack}
                    />
                ))
            }
            {
                (user.Hand.length <= 0) &&
                <img
                    src={placeholderImage}
                    className={"w-[40px] md:w-[75px] relative z-20 -ml-[60px] -left-[25px]"}
                    alt={"placeholderImage"}
                />
            }
        </div>
        <div className={'rotate-180 text-center'}>
            <div className={'text-xl text-white flex justify-center gap-2 text-center p-0 m-0'}>
                <p>{user.Name}</p>
                <PlayerCalls player={user} />
            </div>
        </div>
    </div>
}

export default LeftPlayerField;