import {useEffect, useRef} from "react";

const GameLog = ( {gameLog}) => {
    const messageRef = useRef();

    useEffect(() => {
        if (messageRef && messageRef.current) {
            const {scrollHeight, clientHeight} = messageRef.current;
            messageRef.current.scrollTo({
                left: 0, top: scrollHeight - clientHeight,
                behavior: 'smooth',
            });
        }
    }, [gameLog]);

    return (
        <div>
            <div className='rounded-lg pr-1'>
                <div className='grid'>
                    <div ref={messageRef} className='h-[300px] w-[400px] overflow-auto rounded-sm mb-2 small-scrollbar'>
                        {gameLog.map((log, logIndex) => (
                            <div key={logIndex} className="ml-3 mb-4 mt-4 flex flex-row">
                                {log.LogMessages.map((m, index) => (
                                    <div key={index} className={`text-md mr-1`}>
                                        <div className={`text-md rounded-lg ${m.Type === 0 ? 'text-blue-500 font-medium' : 'text-white'}`}>
                                            {m.Message}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GameLog;