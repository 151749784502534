import {useState} from "react";

const SendMessageForm = ({sendmessage}) => {
    const [message, setMessage] = useState('');

    return <form
        onSubmit={e => {
            e.preventDefault();
            sendmessage(message);
            setMessage('');
        }}
    >
        <div className="flex items-center">
            <div className="flex justify-between w-full">
                <input
                    className="w-full p-2 bg-gray-800 rounded-l text-white focus:outline-none hover:border-none"
                    type="text"
                    placeholder="message..."
                    onChange={e => setMessage(e.target.value)}
                    value={message}
                />
                <button type="submit" disabled={!message} className="w-10 bg-gray-800 rounded-r">
                    <span className="material-symbols-outlined text-gray-500 pt-2">
                        send
                    </span>
                </button>
            </div>
        </div>
    </form>
}

export default SendMessageForm;